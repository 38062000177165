import * as React from 'react'
import Header from '../components/header'
import { Container, Row, Col } from 'react-bootstrap'
import { withPrefix } from 'gatsby'

const AboutPage = () => {
  return (
    <>
    <Header activePage="Projects" />
    <Container>
        <h1 style={{paddingTop: "15px"}}>Projects</h1>
        <Row>
          <h3>Bon Voyage (2020)</h3>
          <h6>Students: Lesley Lock, Demi Jansen, Alex de Kruijff, Axel van Boxtel</h6>
        </Row>
        <Row  style={{padding: "25px"}}>
          <Col xs={4}>
          <img src={withPrefix('../bv.png')} width={400} />
          </Col>
          <Col style={{textAlign: "justify"}} xs={8}>
          <p>This project explores how indoor climate data can support and enable ludic engagements. With an initial focus on well-being, the insights from the contextual step highlighted opportunities for designing for more playful and open-ended forms of interaction. The resulting concept is Bon Voyage: a system that invites for a novel interaction with air quality by taking inspiration from ludic engagement. The system allows the user to explore the indoor air quality by moving sensors throughout the living environment. The sensor data is communicated by means of a thermal printer, which uses creative and open-ended interpretations to inform the user about data (i.e. printing images of plants to communicate humidity, QR codes of songs to communicate temperature and messages with the probability of combustion to communicate gas levels). These interpreted messages hide the actual values, supporting ambiguity about the actual measurements, and provide information that slightly overlaps with everyday interests of the user.</p>
          </Col>
        </Row>
        <Row>
          <h3>The Reflection Garden (2021)</h3>
          <h6>Students: Wesley Hartogs, Inge Hootsmans, Jef Rouschop, Minne Zeijdner</h6>
        </Row>
        <Row style={{padding: "25px"}}>
          <Col xs={4}>
           <img src={withPrefix('../rg.jpeg')} width={400} />
          </Col>
          <Col style={{textAlign: "justify"}} xs={8}>
          <p>This project explores self-reflection through abstract self-reported data acquisition. Good understanding of a person’s own behaviour and mood can help in the prevention of mental health issues. Three data-tracking prototypes were deployed in context to explore how reflection could lead to more self-awareness. Based on the contextual step, the Reflection Garden was designed. Playing into the inherently personal nature and experience of moods, the Reflection Garden encourages the user to define their own mood with the help of physical tokens. Thirteen abstract tokens were crafted, each with an abstract, undefined shape intended to provoke a participant to attach their own definition. In a user deployment, it was explored how a token-based prototype with matching exercises can help in creating conscious awareness of mood.</p>
          </Col>
        </Row>
        <Row>
          <h3>Not the FBI (2021)</h3>
          <h6>Students: Iris Bataille, Charlaine Janssen, Lars de Langen, Mathias Verheijden</h6>
        </Row>
        <Row style={{padding: "25px"}}>
          <Col xs={4}>
          <img src={withPrefix('../fbi.png')} width={400} /> 
          </Col>
          <Col style={{textAlign: "justify"}} xs={8}>
          <p>During the COVID-19 pandemic, having to spend more time at home increases the need for a well-ventilated home with a good air quality. This project explores the design of an intervention to improve air quality at home. The current situation of participants was explored through collecting data about the air quality in their home environment. Based on these findings, a system consisting of data trackers, a Telegram chatbot and ambient notifications through smart lights in the house was designed. By providing information on the air quality data in different ways, the possibilities for communicating the air quality to the user were explored. After analyzing the different types of data communication, a distinction was made between information that requires urgent notifications (through smart lights) and less urgent but detailed notifications (through the Telegram chatbot).</p>
          </Col>
        </Row>
        <Row>
          <h3>Air quality dashboard (2021)</h3>
          <h6>Students: Kelly Fransen, Chantal Vriens, Mervyn Franssen, Maud Oomen</h6>
        </Row>
        <Row style={{padding: "25px"}}>
          <Col xs={4}>
          <img src={withPrefix('../air.png')} width={400} />          
          </Col>
          <Col style={{textAlign: "justify"}} xs={8}>
          <p>This study explores the new working-from-home domain in terms of self-reported productivity and air quality. Through the exploration of air quality and the perceived productivity of WFH office workers, we can conclude that people have little understanding of air quality regulation in their own workspace even though there is a desire to know more about this quality. A real-time visualisation of air quality data was introduced, with a focus on creating a comprehensive overview of the air quality that could be consulted by its users when desired.</p>
          </Col>
        </Row>
    </Container>
    </>
  )
}
export default AboutPage